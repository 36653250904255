import { css } from '@amedia/brick-tokens';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const opinionBodyLayout = {
  variants: {
    version: {
      imageLeft: {
        '[data-theme="charlie"] &': {
          gridTemplateColumns: 'calc(34% + 3%) 1fr',
          gridTemplateAreas: '"image title"',
          gridRowGap: '$teaserStackBM',
          columnGap: '$teaserStackAM',

          '&.pills': {
            gridTemplateAreas: '"image pills" "image title"',
          },
          '&.premium': {
            gridTemplateAreas: '"image title premium"',
          },
          '&.pills.premium': {
            gridTemplateAreas: '"image pills pills" "image title premium"',
          },

          '@bp532': {
            gridRowGap: '$teaserStackBL',
            columnGap: '$teaserStackAL',
            '& .opinion-pills, & .title_container': {
              //x- and y-axis switch places on horizontal teaser layout
              padding: '$teaserContentXInsetL $teaserContentYInsetL',
            },
          },
        },

        '[data-theme="alfa"] &.pills, [data-theme="charlie"] &.pills': {
          gridTemplateRows: 'auto',
        },

        '&.mirror, &.mirror.premium': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          gridTemplateAreas: '"title image"',
          columnGap: 'calc($space$teaserStackAM + $space$teaserBoxInsetM)',
          gridArea: '"title image" "title image"',
          gridRowGap: '$teaserStackBM',
        },
        '&.mirror.pills, &.mirror.pills.premium': {
          gridTemplateAreas: '"pills image" "title image"',
        },
        '&.mirror .premium_container': {
          gridArea: 'image',
          zIndex: '2',
          padding: '$teaserBoxInsetM',
          '@bp532': {
            padding: '$teaserBoxInsetL',
          },
        },
      },
    },
  },
};

export const imageOpinionLayoutStyle = css({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackAM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: '"image" "title"',

  //to be sure that imageLeft and default stylees are working properly with scorponok we need to target the themes like this
  '[data-theme="alfa"], [data-theme="charlie"] &': {
    gridColumnGap: 0,
  },
  '[data-theme="alfa"] &.pills, [data-theme="charlie"] &.pills': {
    gridTemplateAreas: '"image" "pills" "title"',
    gridTemplateRows: 'auto auto 1fr',
  },
  '[data-theme="alfa"] &.premium, [data-theme="charlie"] &.premium': {
    gridTemplateAreas: '"image image" "title premium"',
  },
  '[data-theme="alfa"] &.pills.premium, [data-theme="charlie"] &.pills.premium':
    {
      gridTemplateAreas: '"image image" "pills pills" "title premium"',
    },
  '[data-theme="bravo"] &.premium': {
    gridTemplateAreas: '"image image" "title title" ". premium"',
  },

  '& .teaser_image': {
    gridArea: 'image',
  },
  '@bp532': {
    rowGap: '$teaserStackAL',
    columnGap: '$teaserStackBL',
  },

  variants: {
    version: {
      imageLeft: {
        ...opinionBodyLayout.variants.version.imageLeft,
      },
      default: {},
    },
  },
});

export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',
});

export const noImageOpinionLayoutStyle = css({
  '--_b-noImage-padding-opinion-content':
    'var(--brick-space-teaserContentYInsetM) var(--brick-space-teaserContentXInsetM)',

  display: 'grid',
  gridTemplateRows: 'auto',
  rowGap: '$teaserStackBM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',

  padding:
    'var(--b-teaser-padding-noImageContent ,var(--_b-noImage-padding-opinion-content))',

  '@bp532': {
    '--_b-noImage-padding-opinion-content':
      'var(--brick-space-teaserContentYInsetL) var(--brick-space-teaserContentXInsetL)',
    rowGap: '$teaserStackBL',
    columnGap: '$teaserStackBL',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
    byline: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      pills: true,
      premium: false,
      byline: true,
      css: {
        gridTemplateAreas: '"byline pills" "title title"',
      },
    },
    {
      pills: true,
      premium: false,
      byline: false,
      css: {
        gridTemplateAreas: '"pills pills" "title title"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"title title" ". premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"byline pills" "title title" ". premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"title title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"byline pills" "title title"',
      },
    },
  ],
});

export const opinionStyle = css({
  container: 'teaser-content / inline-size',

  variants: {
    marker: {
      true: {
        gap: 0,
        '@bp532': {
          gap: 0,
        },
      },
    },
  },
  defaultVariants: {
    marker: false,
  },
});

export const premiumWrapper = css({
  '--_b-opinion-premium-top-inset': 0,
  '--_b-opinion-premium-wrapper': 'var(--_b-opinion-premium-top-inset) 0 0 0',

  padding:
    'var(--b-teaser-padding-premium-wrap, var(--_b-opinion-premium-wrapper))',

  '@bp532': {
    rowGap: '$teaserStackBL',
    columnGap: '$teaserStackBL',
  },
  display: 'flex',
  placeSelf: 'end',
  boxSizing: 'border-box',

  variants: {
    theme: {
      alfa: { gridArea: 'premium' },
      bravo: {
        gridArea: 'premium',
        '--_b-opinion-premium-top-inset':
          'calc(var(--brick-space-teaserStackCM) - var(--brick-space-teaserStackBM))',

        '@bp532': {
          '--_b-opinion-premium-top-inset':
            'calc(var(--brick-space-teaserStackCL) - var(--brick-space-teaserStackBL))',
        },
      },
      charlie: { gridArea: 'premium' },
      nettavisen: {},
      alt: {},
    },
    hasByline: {
      true: {},
      false: { placeSelf: 'end' },
    },
    hasImage: {
      true: {},
      false: { gridArea: 'premium' },
    },
  },
  defaultVariants: {
    hasByline: false,
  },
  compoundVariants: [
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        '--_b-opinion-premium-wrapper': 'var(--brick-space-teaserBoxInsetM)',
        '@bp532': {
          '--_b-opinion-premium-wrapper': 'var(--brick-space-teaserBoxInsetL)',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        '--_b-opinion-premium-wrapper':
          'var(--_b-opinion-premium-top-inset) var(--brick-space-teaserContentXInsetM) var(--brick-space-teaserContentYInsetM) 0',
        '@bp532': {
          '--_b-opinion-premium-wrapper':
            'var(--_b-opinion-premium-top-inset) var(--brick-space-teaserContentXInsetL) var(--brick-space-teaserContentYInsetL) 0',
        },
      },
    },
  ],
});

export const pillContainer = css({
  '--_b-opinion-pills-padding': 0,
  variants: {
    hasImage: {
      true: {},
      false: {
        gridArea: 'pills',
      },
    },
    marker: {
      true: {},
      false: {},
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
  },
  deafaultVariants: {
    marker: false,
  },
  compoundVariants: [
    {
      hasImage: true,
      theme: 'alfa',
      css: {
        gridArea: 'pills',
        alignSelf: 'start',

        paddingLeft: 'var(--b-teaser-padding-content-left, 0)',
        paddingTop: 'var(--b-teaser-padding-content-top, 0)',
      },
    },
    {
      hasImage: true,
      theme: 'bravo',
      css: {
        '--_b-opinion-pills-padding':
          '0 0 var(--brick-space-teaserBoxInsetM) var(--brick-space-teaserBoxInsetM)',
        gridArea: 'image',
        zIndex: 2,
        padding:
          'var(--b-teaser-padding-overlay ,var(--_b-opinion-pills-padding))',
        display: 'flex',
        placeSelf: 'end',
        justifySelf: 'start',
        '@bp532': {
          '--_b-opinion-pills-padding':
            '0 0 var(--brick-space-teaserBoxInsetL) var(--brick-space-teaserBoxInsetL)',
        },
      },
    },
    {
      hasImage: true,
      theme: 'charlie',
      css: {
        gridArea: 'pills',
        alignSelf: 'start',

        paddingLeft: 'var(--b-teaser-padding-content-left, 0)',
        paddingTop: 'var(--b-teaser-padding-content-top, 0)',
      },
    },
    {
      hasImage: true,
      theme: 'nettavisen',
      marker: true,
      css: {
        gridArea: 'image',
        zIndex: 2,
        display: 'flex',
        alignSelf: 'end',
        justifySelf: 'start',
      },
    },
    {
      hasImage: true,
      theme: 'nettavisen',
      marker: false,
      css: {
        gridArea: 'image',
        zIndex: 2,
        display: 'flex',
        alignSelf: 'end',
        justifySelf: 'start',
        paddingLeft:
          'var(--b-teaser-padding-overlay ,var(--brick-space-teaserBoxInsetM))',
        paddingBottom:
          'var(--b-teaser-padding-overlay ,var(--brick-space-teaserBoxInsetM))',
      },
    },
  ],
});

export const titleContainerStyle = css({
  '--_b-opinion-title-padding-top': 0,
  '--_b-opinion-title-padding-right': 0,
  '--_b-opinion-title-padding-bottom': 0,
  '--_b-opinion-title-padding-left': 0,

  paddingTop:
    'var(--b-teaser-padding-content-top, var(--_b-opinion-title-padding-top))',
  paddingRight:
    'var(--b-teaser-padding-content-right, var(--_b-opinion-title-padding-right))',
  paddingBottom:
    'var(--b-teaser-padding-content-bottom, var(--_b-opinion-title-padding-bottom))',
  paddingLeft:
    'var(--b-teaser-padding-content-left, var(--_b-opinion-title-padding-left))',

  gridArea: 'title',
  display: 'flex',
  flexDirection: 'column',
  gap: '$teaserStackTitleM',
  flex: '1',

  //@media over 532
  '@bp532': {
    gap: '$teaserStackTitleL',
  },
  // @media (min-width: 533px) and (max-width: 1000px)
  '@bp1': {
    gap: '$teaserStackTitleL',
  },

  variants: {
    theme: {
      nettavisen: {},
      bravo: {
        '--_b-opinion-title-padding-top':
          'calc($space$teaserContentYInsetM * 2)',
        '--_b-opinion-title-padding-right':
          'calc($space$teaserContentXInsetM * 2)',
        '--_b-opinion-title-padding-bottom':
          'calc($space$teaserContentYInsetM * 2)',
        '--_b-opinion-title-padding-left':
          'calc($space$teaserContentXInsetM * 2)',

        '@bp532': {
          '--_b-opinion-title-padding-top':
            'calc($space$teaserContentYInsetL * 2)',
          '--_b-opinion-title-padding-right':
            'calc($space$teaserContentXInsetL * 2)',
          '--_b-opinion-title-padding-bottom':
            'calc($space$teaserContentYInsetL * 2)',
          '--_b-opinion-title-padding-left':
            'calc($space$teaserContentXInsetL * 2)',
        },
      },
      alfa: {},
      charlie: {},
      alt: {},
    },
    marker: {
      true: {
        gap: 0,
        rowGap: 0,
        '@bp532': {
          gap: 0,
          rowGap: 0,
        },
        '@bp1': {
          gap: 0,
          rowGap: 0,
        },
        '& span[itemprop="marker"]': {
          display: 'inline',
          padding: '0px 6px 2px',
          '-webkit-box-decoration-break': 'clone',
          boxDecorationBreak: 'clone',
          paddingTop: '0.08em',
          paddingBottom: '0.1em',
          lineHeight: '130%',
        },
      },
    },
  },
  defaultVariants: {
    theme: '',
    marker: false,
  },
});

export const gradientStyle = css({
  background:
    'linear-gradient(165deg, rgba(0, 0, 0, 0.7) 3%, rgba(0, 0, 0, 0.6) 6%, rgba(0, 0, 0, 0.5) 8%, rgba(0, 0, 0, 0.4) 11%, rgba(0, 0, 0, 0.3) 14%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.1) 24%, transparent 32%)',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '$teaserBoxInsetM',

  '@bp532': {
    padding: '$teaserBoxInsetL',
  },
});
