import { Design, getThemeByDesign } from '@amedia/brick-tokens';

import {
  Ghost,
  TeaserApi,
  Teaser,
  Image,
  TeaserType,
  BodyPills,
  OverlayPills,
  Overlay,
  PremiumVersion,
  Title,
  BreakingNews,
  ToggleOptions,
  Vignette,
} from '../types';
import { getSkin, mapToSkin } from '../utils/getSkin';
import { mapToTeaserType } from '../utils/mapToTeaserType';
import { mapToCommercialCategory } from '../utils/mapToCommercialCategory';
import { removeHTMLTagsFromString } from '../utils/removeHTMLTagsFromString';
import { getCountdowns } from '../utils/getCountdowns';
import { mapToBgColor } from '../utils/mapToBgColor';
import { mapToBreakingSkin } from '../components/breaking-text/utils';
import { getImageData } from '../utils/getImageData.js';
import { useMarker } from '../utils/useMarker';

const ghostTypes = ['alt', 'untold'];

const isGhost = (type: TeaserType) => Boolean(ghostTypes.includes(type));
const getPremiumVersion = (data: TeaserApi): PremiumVersion | undefined => {
  if (data.isPremiumAll) return 'plusall';

  if (data.premium || data.isPremium) return 'plus';

  return undefined;
};

const useMirror = (theme: Design, marker: boolean): boolean => {
  return marker ? false : theme === 'nettavisen' || theme === 'alfa';
};

function getTheme(theme?: Design) {
  if (!theme) {
    return 'alfa';
  }
  return theme;
}

function returnSafeId(id): string {
  if (typeof id === 'string') return id;
  if (typeof id === 'number') {
    return id.toString();
  }

  return '';
}

const ndashPattern = /(^|:\s+)([\u002d\u2010-\u2015]\s)/g;

/**
 * For all  themes the pill will trigger for paid content - if no vignette text, "Annonse" will be rendered,
 * else the vignette text
 */

function getVignetteText(isContentMarketing, vignette): string {
  if (isContentMarketing && !vignette) {
    return 'Annonse';
  }

  return vignette;
}

const validateAspectRatio = (input: string): boolean => {
  const aspectRatioRegex = /^\d+:\d+$/;
  return aspectRatioRegex.test(input);
};

export function mapToTeaser(data: TeaserApi): Teaser {
  const teaserId = returnSafeId(data.id);
  const url = data.url || '';
  const videoMeta = {
    ...(data.videoMeta?.videoDuration && {
      videoDuration: data.videoMeta?.videoDuration,
    }),
    ...(data.videoMeta && {
      playOnFront: data.videoMeta?.playOnFront || false,
    }),

    ...(data.videoMeta?.videoId && {
      videoId: data.videoMeta?.videoId,
    }),
    ...(data.videoMeta?.playerId && {
      playerId: data.videoMeta?.playerId,
    }),
    ...(data.videoMeta?.aspectRatio &&
      validateAspectRatio(data.videoMeta?.aspectRatio) && {
        aspectRatio: data.videoMeta?.aspectRatio,
      }),
  };
  let imageData = data.image;

  if (data.videoPreview) {
    imageData = undefined;
  }
  const hasImage =
    !!imageData?.srcset ||
    !!imageData?.url ||
    !!data.videoPreview?.thumbnailUrl;
  const amediaReferrer = Boolean(data.amediaReferrer);
  const commercialCategory = mapToCommercialCategory({
    teaserType: data.type as TeaserType,
    sponsored: !!data.sponsored,
    url,
  });
  const isContentMarketing = !!commercialCategory;

  const teaserType = mapToTeaserType(data.type, isContentMarketing);
  const isGhostTeaser = isGhost(teaserType);
  const premiumVersion = isGhostTeaser ? undefined : getPremiumVersion(data);
  const theme = getTheme(data.theme);
  const backgroundColor = mapToBgColor({
    color: data.backgroundColor || '',
    theme,
    isBreaking: data.breakingNews || false,
  });
  const themeClass = getThemeByDesign(data.theme || 'alfa').toString();
  const marker = useMarker({
    theme,
    color: backgroundColor || '',
    teaserType,
    hasImage,
  });
  const mirror = useMirror(theme, marker);
  const skinFromBg = getSkin(backgroundColor || '');
  const hasByline = !!data.authorName;

  //checks the title for the combination : – , and adds a no line break between – and next word
  const formattedTitle = data.title?.replace(
    ndashPattern,
    (m, p1) => `${p1}\u2013\u2060\u00a0`
  );

  const toggleOptions: ToggleOptions = {
    ...(data.countdown?.toggleText && {
      countdownText: data.countdown.toggleText || '',
      countdownVersion: data.countdown.toggleVersion,
    }),
  };

  const vignetteText = getVignetteText(isContentMarketing, data.vignette);
  const skin = mapToSkin({
    theme,
    skin: skinFromBg,
    isBreaking: data.breakingNews || false,
    filled: marker,
    commercialCategory,
    teaserType,
  });

  const { countdown } = getCountdowns(data.countdown);

  const vignette: Vignette = {
    vignetteClass: data.vignetteClass,
    vignette: vignetteText,
    size: data.size,
    version: data.version,
    largeVignette: data.largeVignette,
    ...(data.vignetteColor && {
      vignetteColor: mapToBgColor({
        color: data.vignetteColor,
      }),
    }),
    ...(data.opinionType && { opinionType: data.opinionType }),
  };

  const breakingNews: BreakingNews = {
    breakingNews: data.breakingNews,
    breakingNewsPosition: data.breakingNewsPosition,
    breakingNewsColor: data.breakingNewsColor,
    ...(data.breakingNewsText && { breakingNewsText: data.breakingNewsText }),
    theme,
    skin: mapToBreakingSkin(skin),
  };

  const ghost: Ghost | undefined = isGhostTeaser
    ? {
        type: teaserType,
        publicationName: data.publicationName,
      }
    : undefined;

  const bodyPills: BodyPills = {
    vignette,
    breakingNews,
    teaserType,
    theme,
    countdown: countdown?.position !== 'bottom' ? countdown : undefined,
    marker,
    skin,
    videoMeta: data.videoMeta,
    ghost,
    hasByline,
    commercialCategory,
    text: data.markingVariant,
  };

  const overlayPills: OverlayPills = {
    teaserType,
    theme,
    breakingNews,
    vignette,
    countdown: countdown?.position !== 'bottom' ? countdown : undefined,
    marker,
    skin,
    commercialCategory,
    text: data.markingVariant,
  };

  const overlay: Overlay = {
    premiumVersion,
    teaserType,
    version: data.version,
    theme,
    skin,
    videoMeta: data.videoMeta,

    ghost,
    mirror,
    marker,
    pills: overlayPills,
    ...(data.authorName && { authorName: data.authorName }),
    ...(data.authorRole && { authorRole: data.authorRole }),
    ...(data.authorImage && { authorImage: getImageData(data.authorImage) }),
  };

  const image: Image = {
    teaserType,
    backgroundColor,
    imageData: getImageData(imageData),
    version: data.version,
    overlay,
    theme,
    title: formattedTitle,
    marker,
    commercialCategory,
  };

  function getRating() {
    const acceptedRatings = ['1', '2', '3', '4', '5', '6'];
    if (data?.rating && acceptedRatings.includes(data.rating)) {
      return data.rating;
    }
    return '';
  }

  const title: Title = {
    premiumVersion,
    title: formattedTitle,
    teaserType,
    ...(data.overline && { overline: data.overline }),
    ...(data.overlineColor && { overlineColor: data.overlineColor }),
    imageData,
    breakingNews,
    isRelatedTeaser: data.isRelatedTeaser,
    version: data.version,
    theme,
    marker,
    skin,
    rating: getRating(),
  };

  return {
    videoMeta,
    premium: data.premium,
    isPremiumAll: data.isPremiumAll,
    id: teaserId,
    externalId: data.externalId,
    size: data.size,
    version: data.version,
    breakingNews,
    theme,
    teaserType,
    themeClass,
    amediaReferrer,
    skin,
    body: {
      backgroundColor,
      url: data.url || '',
      version: data.version,
      image,
      ...(data.videoPreview && { videoData: data.videoPreview }),
      vignette,
      title,
      theme,
      mirror,
      marker,
      pills: bodyPills,
      premiumVersion,
      isContentMarketing,
      teaserType,
      hasByline,
      hasImage,
    },
    footer: {
      skin,
      tipBoxText: removeHTMLTagsFromString(data.tipBoxText || ''),
      tipUs: data.tipUs,
      marker,
      theme,
      teaserType,
      teaserHasImage: hasImage,
      ...(data.authorName && { authorName: data.authorName }),
      ...(data.authorRole && { authorRole: data.authorRole }),
      ...(data.authorImage && {
        authorImage: getImageData(data.authorImage),
      }),
    },
    ...(data.authorName && {
      byline: {
        authorName: data.authorName,
        ...(data.authorRole && { authorRole: data.authorRole }),
        ...(data.authorImage && {
          authorImage: getImageData(data.authorImage),
        }),
      },
    }),
    adp: {
      id: teaserId,
      testId: data.testId,
      variantId: data.variantId,
      siteId: data.siteId,
    },
    meta: data.meta || [],
    sportsMeta: data.sportsMeta,
    isContentMarketing,
    ...(Object.keys(toggleOptions).length > 0 && {
      toggleOptions,
    }),
    countdown,
  };
}
